// DS V2
import { ComponentType, ReactNode, isValidElement } from 'react';
import clsx from 'clsx';
import Typography from '@/components/ui/typography';

export interface PageTitleProps {
  buttons?: ReactNode;
  fullWidth?: boolean;
  subtitle?: string | string[] | ReactNode;
  title: string;
}

export const PageTitle: ComponentType<PageTitleProps> = ({
  buttons,
  fullWidth = true,
  subtitle,
  title,
}) => {
  const renderSubtitle = () => {
    if (typeof subtitle === 'string') {
      return (
        <Typography className="text-gray-500" variant="text-body-sm">
          {subtitle}
        </Typography>
      );
    }
    if (Array.isArray(subtitle)) {
      return subtitle.map((text, index) => (
        <Typography
          key={`subtitle-${index}`}
          className="text-gray-500"
          variant="text-body-sm"
        >
          {text}
        </Typography>
      ));
    }

    if (typeof subtitle !== 'string' && isValidElement(subtitle)) {
      return subtitle;
    }
  };

  return (
    <div className="flex min-h-[44px] items-center justify-between border-b px-4 py-5 md:border-none md:p-0">
      {subtitle ? (
        <div
          className={clsx(
            `inline-flex shrink grow basis-0 flex-col items-start justify-start gap-1`,
            fullWidth && 'w-max'
          )}
        >
          <Typography variant="text-display-md">{title}</Typography>
          {renderSubtitle()}
        </div>
      ) : (
        <Typography variant="text-display-md">{title}</Typography>
      )}
      {buttons ? buttons : null}
    </div>
  );
};

export default PageTitle;
