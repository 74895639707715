// DS V2
import { ComponentType, Fragment, ReactElement, SVGProps } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {
  TrashIcon,
  CheckIcon,
  ExclamationIcon,
  InformationCircleIcon,
  XIcon,
} from '@heroicons/react/outline';
import { PaperAirplaneIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import Button from '@/components/ui/button';
import Typography from '@/components/ui/typography';

export type ModalIconVariant =
  | 'success'
  | 'warning'
  | 'critical'
  | 'info'
  | 'send';

export interface ModalProps {
  CustomIcon?: (props: SVGProps<SVGSVGElement>) => ReactElement | null;
  align?: 'left' | 'center';
  allowOverflow?: boolean;
  backgroundClasses?: string;
  children?: React.ReactNode;
  className?: string;
  customDescription?: React.ReactNode;
  description?: string;
  hasCloseButton?: boolean;
  header?: string;
  initialFocus?: React.MutableRefObject<HTMLElement | null>;
  isPrimaryButtonDestructive?: boolean;
  loading?: boolean;
  /**
   *Tailwind CSS max width class eg. max-w-lg
   */
  maxWidth?: string;
  onClose: () => void;
  open: boolean;
  primaryButtonAction?: () => void;
  primaryButtonText?: string;
  primaryButtonType?: 'button' | 'submit';
  secondaryButtonAction?: () => void;
  secondaryButtonText?: string;
  showChildrenWithVariant?: boolean;
  variant?: ModalIconVariant;
}

export const Modal: ComponentType<ModalProps> = ({
  CustomIcon,
  align,
  allowOverflow,
  backgroundClasses,
  children,
  className,
  customDescription,
  description,
  hasCloseButton,
  header,
  initialFocus,
  isPrimaryButtonDestructive = false,
  loading,
  maxWidth,
  onClose,
  open,
  primaryButtonAction,
  primaryButtonText,
  primaryButtonType = 'button',
  secondaryButtonAction,
  secondaryButtonText,
  showChildrenWithVariant,
  variant,
}) => {
  const renderIcon = () => {
    switch (variant) {
      case 'critical':
        return <TrashIcon className="h-6 w-6" />;

      case 'warning':
        return <ExclamationIcon className="h-6 w-6" />;

      case 'info':
        return <InformationCircleIcon className="h-6 w-6" />;

      case 'send':
        return <PaperAirplaneIcon className="h-6 w-6 rotate-90" />;

      default:
        return <CheckIcon className="h-6 w-6" />;
    }
  };

  return (
    <Transition appear as={Fragment} show={open}>
      <Dialog
        as="div"
        className={clsx('z-modal relative', !open && 'pointer-events-none')}
        initialFocus={initialFocus}
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={clsx(
              'fixed inset-0 bg-black/25',
              backgroundClasses ? backgroundClasses : ''
            )}
          />
        </Transition.Child>

        <div className="z-modal fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-6 sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={clsx(
                  'relative w-full max-w-lg rounded-lg bg-white px-4 pb-4 pt-5 shadow-xl transition-all sm:my-6 sm:w-full sm:p-6',
                  maxWidth,
                  allowOverflow ? 'overflow-visible' : 'overflow-hidden',
                  !!variant && align === 'left' && 'max-w-[400px]',
                  !!variant && align === 'center' && 'max-w-[640px]',
                  className
                )}
              >
                {hasCloseButton && (
                  <button className="absolute right-4 top-4" onClick={onClose}>
                    <XIcon className="h-5 w-5" />
                  </button>
                )}
                {variant ? (
                  <>
                    <div
                      className={clsx(
                        'space-y-4 sm:space-y-5',
                        align === 'center' && 'items-start gap-6 sm:flex'
                      )}
                    >
                      {CustomIcon ? (
                        <CustomIcon />
                      ) : (
                        <div
                          className={clsx(
                            'flex h-12 w-12 shrink-0 items-center justify-center rounded-[28px] border-8 p-3',
                            variant === 'critical' &&
                              'border-red-50 bg-red-100 text-red-600',
                            variant === 'warning' &&
                              'border-amber-50 bg-amber-100 text-amber-600',
                            variant === 'success' &&
                              'border-green-50 bg-green-100 text-green-600',
                            (variant === 'info' || variant === 'send') &&
                              'border-sky-50 bg-sky-100 text-sky-600'
                          )}
                        >
                          <div className="h-6 w-6">{renderIcon()}</div>
                        </div>
                      )}

                      <div className="w-full space-y-6 sm:space-y-8">
                        <div
                          className={
                            (clsx('space-y-2'),
                            align === 'center' ? 'sm:!-mt-5' : '')
                          }
                        >
                          <Typography
                            className="text-gray-900"
                            variant="text-label-lg"
                          >
                            {header}
                          </Typography>

                          {customDescription}
                          <Typography
                            className="text-gray-500"
                            variant="text-body-md"
                          >
                            {description}
                          </Typography>
                          {children}
                        </div>
                        {showChildrenWithVariant ? children : null}
                        {(!!primaryButtonText || !!secondaryButtonText) && (
                          <div className="w-full items-center justify-start gap-4 space-y-3 sm:flex sm:sm:flex-row-reverse sm:space-y-0">
                            {primaryButtonText && primaryButtonAction && (
                              <Button
                                className="w-full"
                                destructive={isPrimaryButtonDestructive}
                                disabled={loading}
                                type={primaryButtonType}
                                variant="primary"
                                onClick={primaryButtonAction}
                              >
                                {primaryButtonText}
                              </Button>
                            )}

                            {secondaryButtonText && secondaryButtonAction && (
                              <Button
                                className="w-full"
                                disabled={loading}
                                variant="secondary-gray"
                                onClick={secondaryButtonAction}
                              >
                                {secondaryButtonText}
                              </Button>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <>{children}</>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
