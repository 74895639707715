import { ComponentType, ChangeEventHandler } from 'react';
import clsx from 'clsx';
import Typography from '@/components/ui/typography';

export type RangeInputLabel = {};

export interface RangeInputProps {
  description?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  label?: string;
  labelColorClass?: string;
  max?: number;
  min?: number;
  onChange?: (value: number) => void;
  size?: 'sm' | 'md';
  step?: number;
  testId?: string;
  value?: number;
}

export const RangeInput: ComponentType<RangeInputProps> = ({
  description,
  disabled = false,
  fullWidth = false,
  label,
  labelColorClass = 'text-gray-800',
  max = 100,
  min = 0,
  onChange,
  size = 'sm',
  step = 1,
  testId,
  value = 0,
}) => {
  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (onChange) {
      onChange(event.target.valueAsNumber);
    }
  };

  return (
    <div
      className={clsx('flex flex-col gap-4', fullWidth ? 'w-full' : 'w-auto')}
    >
      {label ? (
        <div className="min-w-0 flex-1">
          <Typography
            className={labelColorClass}
            variant={size === 'sm' ? 'text-button-sm' : 'text-button-md'}
          >
            {label}
          </Typography>
          {description ? (
            <Typography
              className="text-gray-600"
              variant={size === 'sm' ? 'text-body-sm' : 'text-body-md'}
            >
              {description}
            </Typography>
          ) : null}
        </div>
      ) : null}
      <input
        className={clsx(
          'h-2 w-full cursor-pointer appearance-none rounded-lg bg-gray-200 dark:bg-gray-700',
          disabled ? 'cursor-not-allowed' : 'cursor-pointer',
          size === 'sm' && 'h-2',
          size === 'md' && 'h-3'
        )}
        data-test={testId}
        disabled={disabled}
        max={max}
        min={min}
        step={step}
        type="range"
        value={value}
        onChange={handleChange}
      />
    </div>
  );
};

export default RangeInput;
