// DS V2 with TODO
import {
  ComponentType,
  CSSProperties,
  MouseEventHandler,
  ReactElement,
  SVGProps,
  useMemo,
  HTMLAttributeAnchorTarget,
} from 'react';
import clsx from 'clsx';
import Link from 'next/link';
import styles from '@/components/ui/button.module.css';

export type ButtonVariant =
  | 'primary'
  | 'secondary-gray'
  | 'secondary-neon'
  | 'tertiary-gray'
  | 'tertiary-color'
  | 'link-gray'
  | 'link-color';

export interface ButtonProps {
  LeadingIcon?: (props: SVGProps<SVGSVGElement>) => ReactElement | null;
  MainIcon?: (props: SVGProps<SVGSVGElement>) => ReactElement | null;
  TrailingIcon?: (props: SVGProps<SVGSVGElement>) => ReactElement | null;
  children?: React.ReactNode;
  className?: string;
  destructive?: boolean;
  disabled?: boolean;
  download?: boolean | string;
  form?: string;
  href?: string;
  loading?: boolean;
  loadingText?: string;
  modifier?: 'ai';
  onClick?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  rel?: string;
  size?: 'sm' | 'md';
  style?: CSSProperties;
  target?: HTMLAttributeAnchorTarget;
  testId?: string;
  type?: 'button' | 'submit' | 'reset';
  variant?: ButtonVariant;
}

export const Button: ComponentType<ButtonProps> = ({
  LeadingIcon = () => null,
  MainIcon,
  TrailingIcon = () => null,
  children,
  className,
  destructive,
  disabled,
  onClick,
  rel,
  size = 'md',
  style,
  type = 'button',
  variant = 'primary',
  testId,
  href,
  form,
  target,
  modifier,
  download,
  loading = false,
  loadingText,
}) => {
  const base = new URL(`${window.location.protocol}//${window.location.host}`);
  function isInternal(url: string): boolean {
    return new URL(url, base).hostname === base.hostname;
  }

  const classNames = useMemo(() => {
    const value: string[] = [styles.base];

    // Apply text styles based on size

    switch (size) {
      case 'sm':
        value.push(styles['title-sm']);
        break;
      case 'md':
        value.push(styles['title-md']);
        break;
    }

    // Apply styles based on variant

    if (
      variant === 'primary' ||
      variant === 'secondary-gray' ||
      variant === 'secondary-neon' ||
      variant === 'tertiary-gray' ||
      variant === 'tertiary-color'
    ) {
      if (MainIcon) {
        switch (size) {
          case 'sm':
            value.push(styles['pad-sm-icon']);
            break;
          case 'md':
            value.push(styles['pad-md-icon']);
            break;
        }
      } else {
        switch (size) {
          case 'sm':
            value.push(styles['pad-sm']);
            break;
          case 'md':
            value.push(styles['pad-md']);
            break;
        }
      }
    }

    if (variant === 'primary') {
      if (destructive) {
        value.push(styles['primary-destructive']);
      } else {
        value.push(styles.primary);
      }
    } else if (variant === 'secondary-gray') {
      if (destructive) {
        value.push(styles['secondary-gray-destructive']);
      } else {
        value.push(styles['secondary-gray']);
      }
    } else if (variant === 'secondary-neon') {
      if (destructive) {
        value.push(styles['secondary-neon-destructive']);
      } else {
        value.push(styles['secondary-neon']);
      }
    } else if (variant === 'tertiary-gray') {
      if (destructive) {
        value.push(styles['tertiary-destructive']);
      } else {
        value.push(styles['tertiary-gray']);
      }
    } else if (variant === 'tertiary-color') {
      if (destructive) {
        value.push(styles['tertiary-destructive']);
      } else {
        value.push(styles['tertiary-color']);
      }
    } else if (variant === 'link-gray') {
      if (destructive) {
        value.push(styles['link-destructive']);
      } else {
        value.push(styles['link-gray']);
      }
    } else if (variant === 'link-color') {
      if (destructive) {
        value.push(styles['link-destructive']);
      } else {
        value.push(styles['link-color']);
      }
    }

    if (modifier && modifier === 'ai') value.push(styles['ai-button']);
    return value.join(' ');
  }, [MainIcon, destructive, size, variant, modifier]);

  const content = (
    <>
      {MainIcon ? (
        <MainIcon
          className={clsx(
            size === 'sm' && 'h-5 w-5',
            size === 'md' && 'h-6 w-6'
          )}
        />
      ) : (
        <>
          <LeadingIcon className={clsx('h-5 w-5', loading && 'animate-spin')} />
          {loading ? loadingText || children : children}
          <TrailingIcon className="h-5 w-5" />
        </>
      )}
    </>
  );

  // quick way to get disabled UI when we provide a href and render <Link> instead of <button>
  if (href && (disabled || loading)) {
    return (
      <button
        className={clsx(classNames, className)}
        data-test={testId}
        disabled={disabled || loading}
        style={style}
        type={type}
      >
        {content}
      </button>
    );
  }

  if (href) {
    return (
      <Link
        aria-disabled={disabled || loading}
        className={clsx(
          classNames,
          className,
          loading && 'cursor-not-allowed opacity-70'
        )}
        data-test={testId}
        download={download}
        href={loading ? '#' : href}
        rel={rel}
        style={style}
        target={target ?? (isInternal(href) ? '_self' : '_blank')}
        type={type}
        onClick={loading ? (e) => e.preventDefault() : onClick}
      >
        {content}
      </Link>
    );
  }

  return (
    <button
      className={clsx(classNames, className)}
      data-test={testId}
      disabled={disabled || loading}
      form={form}
      style={style}
      type={type}
      onClick={onClick}
    >
      {content}
    </button>
  );
};

export default Button;
