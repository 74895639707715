import * as React from 'react';
import { Typography } from '@ds';
import {
  AlignHorizontalJustifyCenter,
  AlignHorizontalJustifyEnd,
  AlignHorizontalJustifyStart,
} from 'lucide-react';

type Alignment = {
  horizontal: 'left' | 'center' | 'right';
  vertical: 'top' | 'center' | 'bottom';
};

type AlignmentPickerProps = {
  description?: string;
  label?: string;
  onChange: (value: string) => void;
  value?: string;
};

export function AlignmentPicker({
  description,
  label,
  onChange,
  value = 'center_center',
}: AlignmentPickerProps) {
  const parseAlignment = (value: string): Alignment => {
    if (typeof value !== 'string' || !value.includes('_')) {
      return { horizontal: 'left', vertical: 'top' }; // Default alignment
    }
    const [vertical, horizontal] = value.split('_');
    return {
      horizontal: horizontal as Alignment['horizontal'],
      vertical: vertical as Alignment['vertical'],
    };
  };

  const [alignment, setAlignment] = React.useState<Alignment>(
    value ? parseAlignment(value) : { horizontal: 'left', vertical: 'top' }
  );

  const handleAlignmentChange = (
    vertical: Alignment['vertical'],
    horizontal: Alignment['horizontal']
  ) => {
    const newAlignment = { horizontal, vertical };
    setAlignment(newAlignment);
    onChange(`${vertical}_${horizontal}`);
  };

  const getButtonClasses = (
    v: Alignment['vertical'],
    h: Alignment['horizontal']
  ) =>
    alignment.vertical === v && alignment.horizontal === h
      ? 'bg-primary-green-dark text-white'
      : 'bg-gray-200 text-gray-700';

  const getIcon = (v: Alignment['vertical'], h: Alignment['horizontal']) => {
    if (h === 'left')
      return <AlignHorizontalJustifyStart className="h-4 w-4" />;
    if (h === 'center')
      return <AlignHorizontalJustifyCenter className="h-4 w-4" />;
    if (h === 'right') return <AlignHorizontalJustifyEnd className="h-4 w-4" />;
  };

  return (
    <div className="space-y-4">
      {label ? (
        <div className="min-w-0 flex-1">
          <Typography className="text-gray-800" variant="text-button-sm">
            {label}
          </Typography>
          {description ? (
            <Typography className="text-gray-600" variant="text-body-sm">
              {description}
            </Typography>
          ) : null}
        </div>
      ) : null}
      <div className="inline-grid grid-cols-3 gap-2 rounded-md border p-1">
        {['top', 'center', 'bottom'].map((vertical) =>
          ['left', 'center', 'right'].map((horizontal) => (
            <button
              key={`${vertical}-${horizontal}`}
              aria-label={`Align ${vertical} ${horizontal}`}
              className={`flex h-9 w-9 items-center justify-center rounded ${getButtonClasses(
                vertical as Alignment['vertical'],
                horizontal as Alignment['horizontal']
              )}`}
              type="button"
              onClick={() =>
                handleAlignmentChange(
                  vertical as Alignment['vertical'],
                  horizontal as Alignment['horizontal']
                )
              }
            >
              {getIcon(
                vertical as Alignment['vertical'],
                horizontal as Alignment['horizontal']
              )}
            </button>
          ))
        )}
      </div>
    </div>
  );
}
