// DS V2
import { ComponentType, ReactElement, SVGProps, useMemo } from 'react';
import clsx from 'clsx';
import Typography, { TypographyVariant } from '@/components/ui/typography';

export type BadgeColor =
  | 'amber'
  | 'cyan'
  | 'fuchsia'
  | 'gray'
  | 'green'
  | 'amp-green'
  | 'moss'
  | 'red'
  | 'rose'
  | 'sky'
  | 'sunflower'
  | 'violet';

export type BadgeSize = 'xs' | 'sm' | 'md';

export interface BadgeProps {
  LeadingIcon?: (props: SVGProps<SVGSVGElement>) => ReactElement | null;
  MainIcon?: (props: SVGProps<SVGSVGElement>) => ReactElement | null;
  TrailingIcon?: (props: SVGProps<SVGSVGElement>) => ReactElement | null;
  children?: React.ReactNode;
  className?: string;
  color?: BadgeColor;
  dotLeading?: boolean;
  size?: BadgeSize;
  testId?: string;
}

export const Badge: ComponentType<BadgeProps> = ({
  LeadingIcon = () => null,
  MainIcon,
  TrailingIcon = () => null,
  children,
  className,
  color = 'gray',
  dotLeading,
  size = 'sm',
  testId,
}) => {
  const labelVariant = useMemo<TypographyVariant | undefined>(() => {
    switch (size) {
      case 'xs':
        return 'text-caption';
      case 'sm':
        return 'text-body-sm';
      case 'md':
        return 'text-body-md';
    }
  }, [size]);

  const paddingClassNames = useMemo(() => {
    if (MainIcon) {
      switch (size) {
        case 'xs':
          return 'p-[5px]';
        case 'sm':
          return 'p-1.5';
        case 'md':
          return 'p-[7px]';
      }
    }

    return 'py-0.5 px-2';
  }, [MainIcon, size]);

  return (
    <div
      className={clsx(
        'inline-flex items-center justify-center gap-1 whitespace-nowrap rounded-full mix-blend-multiply',
        color === 'amber' && 'bg-amber-50 text-amber-700',
        color === 'cyan' && 'bg-cyan-50 text-cyan-700',
        color === 'fuchsia' && 'bg-fuchsia-50 text-fuchsia-700',
        color === 'gray' && 'bg-gray-100 text-gray-700',
        color === 'green' && 'bg-green-50 text-green-700',
        color === 'amp-green' && 'bg-amplify-green-50 text-amplify-green-700',
        color === 'moss' && 'text-moss-700 bg-moss-50',
        color === 'red' && 'bg-red-50 text-red-700',
        color === 'rose' && 'bg-rose-25 text-rose-700',
        color === 'sky' && 'bg-sky-25 text-sky-700',
        color === 'sunflower' && 'text-sunflower-700 bg-sunflower-50',
        color === 'violet' && 'bg-purple-50 text-purple-700',
        size === 'xs' && 'min-w-5',
        size === 'sm' && 'min-w-6',
        size === 'md' && 'min-w-7',
        paddingClassNames,
        className
      )}
      data-test={testId}
    >
      {MainIcon ? (
        <MainIcon
          className={clsx(
            color === 'amber' && 'text-amber-500',
            color === 'cyan' && 'text-cyan-500',
            color === 'fuchsia' && 'text-fuchsia-500',
            color === 'gray' && 'text-gray-500',
            color === 'green' && 'text-green-500',
            color === 'amp-green' && 'text-amplify-green-500',
            color === 'moss' && 'text-moss-500',
            color === 'red' && 'text-red-500',
            color === 'rose' && 'text-rose-500',
            color === 'sky' && 'text-sky-500',
            color === 'sunflower' && 'text-sunflower-500',
            color === 'violet' && 'text-purple-500',
            size === 'xs' && 'h-2.5 w-2.5',
            size === 'sm' && 'h-3 w-3',
            size === 'md' && 'h-3.5 w-3.5'
          )}
        />
      ) : (
        <>
          {dotLeading ? (
            <div
              className={clsx(
                'rounded-full',
                color === 'amber' && 'bg-amber-500',
                color === 'cyan' && 'bg-cyan-500',
                color === 'fuchsia' && 'bg-fuchsia-500',
                color === 'gray' && 'bg-gray-500',
                color === 'green' && 'bg-green-500',
                color === 'amp-green' && 'bg-amplify-green-500',
                color === 'moss' && 'bg-moss-500',
                color === 'red' && 'bg-red-500',
                color === 'rose' && 'bg-rose-500',
                color === 'sky' && 'bg-sky-500',
                color === 'sunflower' && 'bg-sunflower-500',
                color === 'violet' && 'bg-purple-500',
                size === 'xs' && 'h-1.5 w-1.5',
                size === 'sm' && 'h-2 w-2',
                size === 'md' && 'h-2 w-2'
              )}
            />
          ) : (
            <LeadingIcon
              className={clsx(
                color === 'amber' && 'text-amber-500',
                color === 'cyan' && 'text-cyan-500',
                color === 'fuchsia' && 'text-fuchsia-500',
                color === 'gray' && 'text-gray-500',
                color === 'green' && 'text-green-500',
                color === 'amp-green' && 'text-amplify-green-500',
                color === 'moss' && 'text-moss-500',
                color === 'red' && 'text-red-500',
                color === 'rose' && 'text-rose-500',
                color === 'sky' && 'text-sky-500',
                color === 'sunflower' && 'text-sunflower-500',
                color === 'violet' && 'text-purple-500',
                size === 'xs' && 'h-2.5 w-2.5',
                size === 'sm' && 'h-3 w-3',
                size === 'md' && 'h-3.5 w-3.5'
              )}
            />
          )}
          <Typography component="span" variant={labelVariant}>
            {children}
          </Typography>
          <TrailingIcon
            className={clsx(
              color === 'amber' && 'text-amber-500',
              color === 'cyan' && 'text-cyan-500',
              color === 'fuchsia' && 'text-fuchsia-500',
              color === 'gray' && 'text-gray-500',
              color === 'green' && 'text-green-500',
              color === 'amp-green' && 'text-amplify-green-500',
              color === 'moss' && 'text-moss-500',
              color === 'red' && 'text-red-500',
              color === 'rose' && 'text-rose-500',
              color === 'sky' && 'text-sky-500',
              color === 'sunflower' && 'text-sunflower-500',
              color === 'violet' && 'text-purple-500',
              size === 'xs' && 'h-2.5 w-2.5',
              size === 'sm' && 'h-3 w-3',
              size === 'md' && 'h-3.5 w-3.5'
            )}
          />
        </>
      )}
    </div>
  );
};

export default Badge;
